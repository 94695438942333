import React from 'react';
const Movie = (props) => {
    const { movie, onClickMovie } = props;
    return (
        <div className='movie' onClick={onClickMovie}>
            <img
                src={
                    movie.poster_path ?
                        `https://image.tmdb.org/t/p/original${movie.poster_path}` :
                        "https://damononroad.s3.ca-central-1.amazonaws.com/notfound.jpg"
                }
                alt="poster" />
            <div className='film-container'>
                <ul>
                    <li>{movie.title}
                        <span className="date">
                            {movie.release_date ? movie.release_date.slice(0, 4) : ""}
                        </span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span>
                            <i className="fas fa-thumbs-up fa-lg" />
                        </span>
                        <span>&nbsp;&nbsp;&nbsp;{movie.vote_average} % </span>
                    </li>
                </ul>
            </div>
        </div >
    )
}
export default Movie;
