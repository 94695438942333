import React from "react";
import BanniereCateg from "../components/categories/BanniereCateg";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import FilmsCategorie from "../components/categories/FilmsCategorie";
const Categories = () => {
  return (
    <>
      <Navigation />
      <main>
        <BanniereCateg />
        <FilmsCategorie />
      </main>
      <Footer />
    </>
  );
};
export default Categories;
