import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie.js';
import API_KEY from "../data/api";
import { useHistory } from 'react-router-dom';
import { data } from 'jquery';
const FilmsPopulaire = () => {
  const [popular, setPopular] = useState([]);
  const history = useHistory();
  useEffect(() => {
    axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=${API_KEY}&language=fr-FR&page=1`).then((res) => setPopular(res.data.results));
  }, []);
  return (
    <div className="categoriesMovies">
      <div className="sort-container">
        <div>
          <div>
            <div>
              <span className="blanc-texte sous-titre">DIFFUSION EN LIGNE</span>
              <h2 className="title">Films les plus populaires</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="sort-container">
        {<ul className="categoriesMovies-list">
          {popular.map((m) => (
            <Movie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails/' + m.id, { id: m.id })} />
          ))}
        </ul>}
      </div>
    </div>
  )
}
export default FilmsPopulaire;
