import React, { useEffect } from 'react'
import "magnific-popup";
import $ from "jquery";
import 'animate.css';
const Banner = () => {
  useEffect(() => {
    $('.popup-video').magnificPopup({
      type: 'iframe'
    });
  }, [])
  return (
    <section className="banner-area banner-bg" style={{ backgroundImage: 'url("../img/bannieres/banner_bg01.jpg")' }}>
      <div className="container custom-container">
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banniere-contenaire ">
              <h2 className="title animate__animated animate__fadeInUp p-3" data-wow-delay=".4s" data-wow-duration="1.8s"><h4>CINÉMA EN LIGNE
                <a href="https://damononroad.s3.ca-central-1.amazonaws.com/Groupe-G-Web.mp4" className="HiLite blanc-texte popup-video"> Voir maintenant <i className="fas fa-play" /></a>
              </h4>Films <span className="blanc-texte">illimités</span>, série et
                plus encore <span className="blanc-texte">!</span></h2>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
export default Banner
