import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useEffect } from "react"
import $ from "jquery";
import Accueil from './pages/Accueil';
import PageContact from './pages/PageContact';
import Search from "./pages/Search";
import Categories from "./pages/Categories";
import MovieDetails from "./pages/MovieDetails";
import ActorDetails from "./pages/ActorDetails";
function App() {
  useEffect(() => {
    function preloader() {
      $('#preloader').delay(0).fadeOut();
    };
    $(window).on('load', function () {
      preloader();
    });
  })
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/search/" exact component={Search} />
          <Route path="/movieDetails/:id" exact component={MovieDetails} />
          <Route path="/actorDetails/:id" exact component={ActorDetails} />
          <Route path="/" exact component={Accueil} />
          <Route path="/categories/" exact component={Categories} />
          <Route path="/pageContact" exact component={PageContact} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}
export default App; 
