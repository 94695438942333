import React, { useEffect } from "react";
import $ from "jquery";
const Navigation = () => {
  useEffect(() => {
    /*=============================================
      =    		Mobile Menu			      =
    =============================================*/
    //SubMenu Dropdown Toggle
    if ($(".zone-menu li.menu-item-has-children ul").length) {
      $(".zone-menu .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }
    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".zone-menu .menu-principal").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);
      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".cell-nav-deroulant").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
    /*=============================================
      =     Menu sticky & Scroll to top      =
    =============================================*/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
    /*=============================================
      =    		 Scroll Up  	         =
    =============================================*/
    if ($(".scroll-to-target").length) {
      $(".scroll-to-target").on("click", function () {
        var target = $(this).attr("data-target");
        // animate
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top,
          },
          1000
        );
      });
    }
  }, []);
  return (
    <header className="entete-style-deux">
      <div className="header-top-wrap">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-md-6 d-none d-md-block">
              <div className="entete-menu-navigation">
                <p>
                  Cinéma En-Ligne vous sohaite la <span>Bienvenue!</span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="header-top-link">
                <ul className="header-social">
                  <li>
                    <a href="https://www.facebook.com/people/Cinema-En-Ligne/100087523249881/">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/themoviedb">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/cinema-enligne-059b28258/">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="zone-menu">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="cell-nav-deroulant">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo">
                    <a href="/#">
                      <img src="../img/logo/logo.png" alt="Logo" />
                    </a>
                  </div>
                  <div className="barre-navigation menu-principal d-none d-lg-flex">
                    <ul className="navigation">
                      <li className="active menu-item-has-children">
                        <a href="/">Accueil</a>
                      </li>
                      <li>
                        <a href="/categories">Categories</a>
                      </li>
                      <li>
                        <a href="/search">Recherche</a>
                      </li>
                      <li>
                        <a href="/pagecontact">À propos</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <div className="close-btn">
                  <i className="fas fa-times" />
                </div>
                <nav className="menu-box">
                  <div className="nav-logo">
                    <a href="/#">
                      <img src="img/logo/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="menu-outer">
                    {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="/#">
                          <span className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-facebook-square" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Navigation;
