import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie.js';
import API_KEY from "../data/api";
import { useHistory } from 'react-router-dom';
const SearchFilm = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const URL = `https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&language=fr-FR&query=${query}`;
    useEffect(() => {
        axios.get(URL).then((res) => {
            setData(res.data.results)
        });
    }, [query]);
    const onSearch = (event) => {
        setQuery(event.target.value);
    }
    return (
        <div className='SearchFilm'>
            <div className='placeholder-container'>
                <input
                    className='input'
                    placeholder='Votre recherche'
                    onChange={onSearch}
                ></input>
            </div>
            {query ? (
                <ul className='SearchFilm-list'>
                    {data.map((movie) => (
                        <Movie movie={movie} key={movie.id} onClickMovie={() => history.push('/movieDetails/' + movie.id, { id: movie.id })} />
                    ))}
                </ul>
            ) : (
                <div className='placeholder-container'>
                    <h4>Rien à afficher, veuillez entrer votre mot de recherche</h4>
                </div>
            )}
        </div>
    )
}
export default SearchFilm;
