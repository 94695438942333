const user = {
    avatarUrl: "https://res.cloudinary.com/dpmimldgh/image/upload/v1643205032/18d9e1307018dbc76750ca7d5124fccd_hcdpid.jpg",
    username: "root",
    password: "123456",
    socials: {
        twitter: "https://twitter.com/",
        instagram: "https://www.instagram.com/"
    },
    joinDate: "December 2021"
}
const userReducer = () => {
    return user;
};
export default userReducer;
