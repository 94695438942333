import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SearchFilm from "../components/SearchFilm";
import EnteteRecherche from "../components/EnteteRecherche";
const Search = () => {
    return(
        <div>
            <Navigation />
            <EnteteRecherche />
            <SearchFilm />
            <Footer />
        </div>
    )
}
export default Search; 
