import React, { useState, useEffect } from "react";
import axios from "axios";
import Actor from "../components/Actor";
import { useHistory } from "react-router-dom";
import API_KEY from "../data/api";
import YouTube from "react-youtube/dist/YouTube";
import Navigation from "../components/Navigation";
const MovieDetails = (props) => {
    const id = props.location.state.id;
    const URL = `https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=fr-FR`;
    const URL_CREDITS = `https://api.themoviedb.org/3/movie/${id}/credits?api_key=${API_KEY}&language=fr-FR`;
    const URL_TRAILER = `https://api.themoviedb.org/3/movie/${id}/videos?api_key=${API_KEY}&language=fr-FR`;
    const [detail, setDetail] = useState({});
    const [genres, setGenres] = useState([]);
    const [credits, setCredits] = useState([]);
    const [trailer, setTrailer] = useState([]);
    const history = useHistory();
    useEffect(() => {
        axios.get(URL).then((res) => {
            setDetail(res.data);
            setGenres(res.data.genres);
        });
        axios.get(URL_CREDITS).then((res) => {
            setCredits(res.data.cast);
        });
        axios.get(URL_TRAILER).then((res) => {
            setTrailer(res.data.results);
        });
    }, [URL, URL_CREDITS, URL_TRAILER]);
    return (
        <React.Fragment>
         <Navigation />
            <div className="details-arriere-plan">
                <img
                    className="details-arriere-plan"
                    src={
                        detail.backdrop_path ?
                            `https://image.tmdb.org/t/p/original${detail.backdrop_path}` :
                            "https://damononroad.s3.ca-central-1.amazonaws.com/Not-Found-Bg.png"
                    }
                    alt="poster"
                />
            </div>
            <div className="details-container">
                <div className="movie_card" id="bright">
                    <div className="info_section">
                        <div className="movie_header">
                            <img className="locandina"
                                src={
                                    detail.poster_path ?
                                        `https://image.tmdb.org/t/p/original${detail.poster_path}` :
                                        "https://damononroad.s3.ca-central-1.amazonaws.com/notfound.jpg"
                                }
                                alt="poster"
                            />
                            <h2>{detail.title}</h2>
                            <h3>{detail.original_title}</h3>
                            <h6>Date de sortie : {detail.release_date}</h6>
                            <h6>Revenue : {detail.revenue}</h6>
                            <span className="minutes">{detail.runtime} min</span>
                            {genres.map((g) => {
                                return (
                                    <p
                                        key={g.id}
                                        className="type"
                                        onClick={() => history.push('/categories', { id: g.id })}>
                                        {g.name}</p>
                                );
                            })}
                            <div className="circle">
                                <div className="rate">
                                    <span className="title timer" data-from="0" data-to="40" data-speed="1800">{Math.round(detail.vote_average * 10)}</span>
                                    <div className="overlay"></div>
                                    <div className="left"></div>
                                    <div className="right"></div>
                                </div>
                            </div>
                        </div>
                        <div className="movie_desc">
                            <p className="text">{detail.overview}</p>
                        </div>
                        {trailer.slice(0, 1).map((t, index) => {
                            return (
                                <div key={index}>
                                    <YouTube videoId={t.key ? t.key : ""}
                                    />
                                    <form action="https://www.youtube.com/watch?v=j3QYUAOGDo0"> <input type="submit" value="" />
                                    </form>
                                </div>
                            );
                        })}
                        <div className="joue mt-4">
                        <p>Acteurs ayant joué dans ce film</p>
                        </div>
                        <ul className="actors_desc">
                            {credits
                                .slice(0, 4)
                                .map(credit => {
                                    return (
                                        <Actor
                                            actor={credit}
                                            key={credit.id}
                                            onClickActor={() => history.push('/actorDetails/' + credit.id, { id: credit.id })} />
                                    );
                                })
                            }
                        </ul>,
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default MovieDetails;
