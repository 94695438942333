import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "../Movie.js";
import API_KEY from "../../data/api";
import { useHistory } from "react-router-dom";
const CategoriesMovies = (props) => {
  const { idGenre } = props;
  const history = useHistory();
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [categories, setCategories] = useState([]);
  const idParam = idGenre ? idGenre : id;
  const URL = `https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&language=fr-FR&page=1&with_genres=${idParam}`;
  const URL_GENRES = `https://api.themoviedb.org/3/genre/movie/list?api_key=${API_KEY}&language=fr-FR`;
  useEffect(() => {
    axios.get(URL).then((res) => {
      setData(res.data.results);
    });
    axios.get(URL_GENRES).then((res) => {
      setCategories(res.data.genres);
    });
  }, [idParam]);
  const onClick = (e) => {
    setId(e.target.value);
  };
  return (
    <div className="categoriesMovies">
      {!idGenre && (
        <div
          className="rej"
          style={{ backgroundImage: 'url("../../img/images/gallery_03.jpg")' }}
        >
          <button
            className="btn btn-outline-danger"
            value={id}
            onClick={onClick}
          >
            {categories.map((genre) => {
              return (
                <button className="btn btn-outline-danger" value={genre.id}>
                  {genre.name}
                </button>
              );
            })}
          </button>
        </div>
      )}
      {idParam ? (
        <ul className="categoriesMovies-list">
          {data.map((movie) => (
            <Movie
              movie={movie}
              key={movie.id}
              onClickMovie={() =>
                history.push("/movieDetails/" + movie.id, { id: movie.id })
              }
            />
          ))}
        </ul>
      ) : (
        <div className="placeholder-container">
          <h4>Rien à afficher, Veuillez sélectionner une catégorie</h4>
        </div>
      )}
    </div>
  );
};
export default CategoriesMovies;
