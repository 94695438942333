import React from "react";
import Entete from "../components/Entete";
import EquipeDetails from "../components/EquipeDetails";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
const PageContact = () => {
  return (
    <>
      <Navigation />
      <main>
        <Entete />
        <EquipeDetails />
      </main>
      <Footer />
    </>
  );
};
export default PageContact;
