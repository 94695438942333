import React, { useState, useEffect } from "react";
import axios from "axios";
import API_KEY from "../data/api";
import Navigation from "../components/Navigation";
import { DataUsage } from "@material-ui/icons";
const ActorDetails = (props) => {
    const id = props.location.state.id;
    const URL = `https://api.themoviedb.org/3/person/${id}?api_key=${API_KEY}&language=fr-FR`
    const URL_JOUER = `https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=${API_KEY}&language=fr-FR`
    const [detail, setDetail] = useState({});
    const [jouer, setJouer] = useState([]);
    useEffect(() => {
        axios.get(URL).then((res) => {
            setDetail(res.data);
        });
    }, [id]);

    useEffect(() => {
        axios.get(URL_JOUER).then((res) => {
            setJouer(res.data.cast.slice(0, 12));
        });
    }, [id]);

    return (

        <React.Fragment>
            <Navigation />
            {console.log(URL_JOUER)}
            <div className="details-arriere-plan">
                {/*                <img
                    className="details-arriere-plan"
                    src={
                        detail.backdrop_path ?
                            `https://image.tmdb.org/t/p/original${detail.backdrop_path}` :
                            "https://films.lynetp.com/wp-content/uploads/2022/12/bg_actor.jpg"
                    }
                    alt="poster"
                /> */}
            </div>
            <div className="details-container">
                <div className="movie_card" id="bright">
                    <div className="info_section">
                        <div className="movie_header">
                            <img
                                className="locandina"
                                src={
                                    detail.profile_path ?
                                        `https://image.tmdb.org/t/p/original${detail.profile_path}` :
                                        "https://damononroad.s3.ca-central-1.amazonaws.com/notfound.jpg"
                                }
                                alt="poster"
                            />
                            <h5>{detail.known_for_department}</h5>
                            <h1>{detail.name}</h1>
                            <h6>Date de naissance : {detail.birthday}</h6>
                            <h6>Date de décès : {detail.deathday}</h6>
                            <span className="minutes">Lieu de naissance : {detail.place_of_birth}</span>
                        </div><br />
                        <div className="movie_desc">
                            <p className="text">{detail.biography}</p><br></br>
                            <div className="section-title text-center ml-5 mt-4 mb-5">
                                 <h2>Célèbre pour</h2>
                             </div>
                        </div>

                    </div>
                    <div className="sort-container">
                    
                        {jouer.map((m) => (
                            <img className="celebre ml-5"
                                height={500}
                                width={350}
                                padding={20}
                                src={
                                    m.poster_path ?
                                        `https://image.tmdb.org/t/p/original/${m.poster_path}` :
                                        "https://damononroad.s3.ca-central-1.amazonaws.com/notfound.jpg"
                                }
                                alt="profile"
                            />
                           
                        ))}
                    </div>
                    <div>


                    </div>
                </div>
            </div>
            <div>


            </div>
        </React.Fragment>
    );
};
export default ActorDetails; 
