import React from "react";
import SlideAccueil from "../components/accueil/SlideAccueil";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FooterAccueil from "../components/accueil/FooterAccueil";
import FilmsPopulaire from "../components/FilmsPopulaire";
const Accueil = () => {
  return (
    <>
      <Navigation />
      <main>
        <SlideAccueil />
        <FilmsPopulaire />
        <FooterAccueil />
      </main>
      <Footer />
    </>
  );
};
export default Accueil;
