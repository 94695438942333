import React from "react";
const EquipeDetails = () => {
  return (
    <section
      className="contact-area contact-bg"
      style={{ backgroundImage: 'url("img/bg/contact_bg.jpg")' }}
    >
      <div className="container-teams">
        <div className="row">
          <div className="col-xl-12">
            <div className="avatar-post mt-40 mb-80">
              <div className="bio-container">
                <div className="image-glow">
                  <div className="bio-title-frame">
                    <div className="text-effect">
                      <h1 className="neon" data-text="Réjean Bisson"></h1>
                      <div className="gradient"></div>
                    </div>
                  </div>
                </div>
                <div className="image-glow">
                  <div className="bio-title-frame">
                    <div className="bio-title-wrapper">
                      <div className="text-effect">
                        <h2 className="neon" data-text="Mubarak Al-Lahabi">
                          Mubarak
                        </h2>
                        <div className="gradient"></div>
                      </div>
                    </div>
                  </div>
                  <div className="bio-pic">
                    <img src="img/images/mubarak.jpg" alt="" />
                  </div>
                </div>
                <div className="content-glow">
                  <div className="bio-content-panel scrollbar">
                    <div className="bio-inner-area">
                      <div className="espace"></div>
                      <div className="espace"></div>
                      <div className="bio-body">
                        <p>
                          <span>Mubarak Al-Lahabi</span>
                        </p>
                        <p>
                          La conception visuelle du projet synthèse est sous la
                          responsabilité de Mubarak.
                        </p>
                      </div>
                    </div>
                    <div className="contact-form-wrap">
                      <div className="espace1"></div>
                      <div className="widget-title mb-50">
                        <h5 className="title">Formulaire de contact</h5>
                      </div>
                      <div className="contact-form">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <input type="text" placeholder="Nom *" />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="email"
                                placeholder="Adresse courriel *"
                              />
                            </div>
                          </div>
                          <input type="text" placeholder="Suject *" />
                          <textarea
                            name="message"
                            placeholder="Écrire votre Message..."
                            defaultValue={""}
                          />
                          <button className="blanc-texte btn btn-danger">
                            Envoyer le Message
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bio-container">
                <div className="image-glow">
                  <div className="bio-title-frame">
                    <div className="text-effect">
                      <h1 className="neon" data-text="Réjean Bisson"></h1>
                      <div className="gradient"></div>
                    </div>
                  </div>
                </div>
                <div className="image-glow">
                  <div className="bio-title-frame">
                    <div className="bio-title-wrapper">
                      <div className="text-effect">
                        <h2 className="neon" data-text="Hugo">
                          Réjean Bisson
                        </h2>
                        <div className="gradient"></div>
                      </div>
                    </div>
                  </div>
                  <div className="bio-pic">
                    <img src="img/images/hugo.jpg" alt="" />
                  </div>
                </div>
                <div className="content-glow">
                  <div className="bio-content-panel scrollbar">
                    <div className="bio-inner-area">
                      <div className="espace"></div>
                      <div className="espace"></div>
                      <div className="bio-body">
                        <p>
                          <span>Hugo Jean</span>
                        </p>
                        <p>
                          L'intégration du projet synthèse est sous la
                          responsabilité de Hugo.
                        </p>
                      </div>
                    </div>
                    <div className="contact-form-wrap">
                      <div className="espace1"></div>
                      <div className="widget-title mb-50">
                        <h5 className="title">Formulaire de contact</h5>
                      </div>
                      <div className="contact-form">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <input type="text" placeholder="Nom *" />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="email"
                                placeholder="Adresse courriel *"
                              />
                            </div>
                          </div>
                          <input type="text" placeholder="Suject *" />
                          <textarea
                            name="message"
                            placeholder="Écrire votre Message..."
                            defaultValue={""}
                          />
                          <button className="blanc-texte btn btn-danger">
                            Envoyer le Message
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bio-container">
                <div className="image-glow">
                  <div className="bio-title-frame">
                    <div className="text-effect">
                      <h1 className="neon" data-text="Réjean"></h1>
                      <div className="gradient"></div>
                    </div>
                  </div>
                </div>
                <div className="image-glow">
                  <div className="bio-title-frame">
                    <div className="bio-title-wrapper">
                      <div className="text-effect">
                        <h2 className="neon" data-text="Réjean">
                          Réjean Bisson
                        </h2>
                        <div className="gradient"></div>
                      </div>
                    </div>
                  </div>
                  <div className="bio-pic">
                    <img src="img/images/rejean.jpg" alt="" />
                  </div>
                </div>
                <div className="content-glow">
                  <div className="bio-content-panel scrollbar">
                    <div className="bio-inner-area">
                      <div className="espace"></div>
                      <div className="espace"></div>
                      <div className="bio-body">
                        <p>
                          <span>Réjean Bisson</span>
                        </p>
                        <p>
                          La programmation du projet synthèse est sous la
                          responsabilité de Réjean.
                        </p>
                      </div>
                    </div>
                    <div className="contact-form-wrap">
                      <div className="espace1"></div>
                      <div className="widget-title mb-50">
                        <h5 className="title">Formulaire de contact</h5>
                      </div>
                      <div className="contact-form">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <input type="text" placeholder="Nom *" />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="email"
                                placeholder="Adresse courriel *"
                              />
                            </div>
                          </div>
                          <input type="text" placeholder="Suject *" />
                          <textarea
                            name="message"
                            placeholder="Écrire votre Message..."
                            defaultValue={""}
                          />
                          <button className="blanc-texte btn btn-danger">
                            Envoyer le Message
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EquipeDetails;
