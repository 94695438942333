import React from 'react'
const FooterAccueil = () => {
  return (
    <section className="services-area services-bg-two" style={{ backgroundImage: 'url("../../img/backgroundimg/services_bg02.jpg")' }}>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="services-img-wrap">
              <img src="img/images/live_img.png" alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="services-content-wrap">
              <div className="section-title mb-40">
                <span className="blanc-texte sous-titre">DIFFUSION EN LIGNE</span>
                <h2 className="rouge-texte title">Choisisez vos films favoris et visionnez-les en différé.</h2>
              </div>
              <div className="services-list">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="flaticon-television" />
                    </div>
                    <div className="content">
                      <h6 className="rouge-texte">Visionner sur l'appareil de votre choix</h6>
                      <p className="blanc-texte">d'un bon film dans le confort de votre maison, c'est aussi que ça!</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-video-camera" />
                    </div>
                    <div className="content">
                      <h6>Profitez à la maison</h6>
                      <p className="blanc-texte">Pour des moments mémorables en famille ou encore entre amis !</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default FooterAccueil
